import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <div className="px-3 row flex-column text-center justify-content-center">
        <h1>404</h1>
        <p>Strona, której szukasz, niestety nie istnieje.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
